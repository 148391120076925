<template>
    <div v-if="postModelData" v-loading="isLoading || isLoadingType || isLoadingCase">
        <div class="row mb-3">
            <div class="col-12 col-md-4">
                <div>
                    <div class="mb-1"><strong>Support type</strong> (typing to filter)</div>
                    <div>
                        <el-select class="w-100" 
                                v-model="postModelData.category_id" 
                                filterable clearable 
                                @change="ticketCategoryChanged"
                                placeholder="Select category of ticket">
                            <el-option v-for="item in ticketCategory"
                                    :key="item.id"
                                    :label="item.displayName"
                                    :value="item.id">
                            </el-option>
                        </el-select>
                    </div>
                </div>
            </div>
            <div class="col-12 col-md-8">
                <div>
                    <div class="mb-1"><strong>Support case</strong> (typing to filter)</div>
                    <div>
                        <el-select class="w-100" 
                                v-model="postModelData.type_id" 
                                filterable clearable 
                                @change="ticketCaseChanged"
                                placeholder="Select case of ticket">
                            <el-option v-for="item in ticketCase"
                                    :key="item.id"
                                    :label="item.displayName"
                                    :value="item.id">
                            </el-option>
                        </el-select>
                    </div>
                </div>
            </div>
            <div class="col-12"
                v-bind:class="{ 'col-md-6' : (postModelData && postModelData.machineId) }">
                <div>
                    <div class="mb-1"><strong>Username</strong></div>
                    <div>
                        <el-input placeholder="Please input username.."  readonly v-model="postModelData.username"></el-input>
                    </div>
                </div>
            </div>
            <div class="col-12 col-md-6" v-if="postModelData.machineId">                
                <div>
                    <div class="mb-1"><strong>Machine ID</strong></div>
                    <div>
                        <el-input placeholder="Please input machine ID.." readonly v-model="postModelData.machineId"></el-input>
                    </div>
                </div>
            </div>
        </div>
        <div class="mb-3">
            <div class="mb-1"><strong>Ticket title</strong></div>
            <div>
                <el-input placeholder="Please input title.." v-model="postModelData.subject"></el-input>
            </div>
        </div>
        <div class="mb-3">
            <div class="mb-1"><strong>Ticket description</strong></div>
            <div>
                <el-input placeholder="Please input description.."
                          type="textarea"
                          :rows="4"
                          v-model="postModelData.mess"></el-input>
            </div>
        </div>
        <div class="text-right">
            <el-button @click="$emit('closeSupportTicket')"><i class="el-icon-circle-close"></i> Close</el-button>
            <el-button type="primary" @click="submitTicket"
                       :disabled="!postModelData.mess || postModelData.mess === '' || !postModelData.subject || postModelData.subject === '' || !postModelData.category_id || postModelData.type_id === null">
                <i class="el-icon-chat-line-round"></i> Send ticket
            </el-button>
        </div>
    </div>
</template>

<script>
    import supportTicketApi from "@/api/common/supportTicketApi";
    export default {
        props: {
            element: {
                type: Object,
                default: null
            },
            postModelData: {
                type: Object,
                default: null
            },
        },
        data() {
            return {
                isLoadingType: false,
                isLoadingCase: false,
                isLoading: false,
                ticketCategory: [],
                ticketCase: [],
            };
        },
        watch: {
            element() {
                this.getTicketCategory();
                this.ticketCategoryChanged();
            }
        },
        mounted() {
            this.getTicketCategory();
            if (this.element && this.postModelData) {
                this.ticketCategoryChanged();
            }
        },
        methods: {
            getTicketCategory() {
                this.isLoadingType = true;
                supportTicketApi.getTicketCategory().then(response => {
                    if (response.data && response.data.result === 0 && response.data.data !== null) {
                        this.ticketCategory = response.data.data;
                    }
                    else {
                        this.ticketCategory = [];
                        if (response.data && response.data.message !== null && response.data.message !== '') {
                            console.error(response.data.message || this.$lang.common.error);
                        }
                    }
                    this.isLoadingType = false;
                }).catch(error => {
                    console.error(error);
                    this.ticketCategory = [];
                    this.isLoadingType = false;
                });
            },            
            getTicketCase() {
                this.isLoadingCase = true;
                this.postModelData.type_id = null;
                supportTicketApi.getTicketType(this.postModelData.category_id).then(response => {
                    if (response.data && response.data.result === 0 && response.data.data !== null) {
                        this.ticketCase = response.data.data;
                    }
                    else {
                        this.ticketCase = [];
                        if (response.data && response.data.message !== null && response.data.message !== '') {
                            console.error(response.data.message || this.$lang.common.error);
                        }
                    }
                    this.isLoadingCase = false;
                }).catch(error => {
                    console.error(error);
                    this.ticketCase = [];
                    this.isLoadingCase = false;
                });
            },
            ticketCategoryChanged() {
                if (this.postModelData) {
                    this.ticketCaseChanged();
                    this.getTicketCase();
                }
            },
            ticketCaseChanged(){
                if (this.postModelData) {
                    let foundItem = this.ticketCategory.find(x => x.id === this.postModelData.category_id);
                    let machineInfoId = this.element.vmMachineId ? `[${this.element.vmMachineId}] ` : "";
                    let targetItemIdentity = this.postModelData.targetItemIdentity ? `[${this.postModelData.targetItemIdentity}] ` : "";
                    if (foundItem && foundItem !== null) {
                        if(this.postModelData.type_id){
                            let foundTypeItem = this.ticketCase.find(x => x.id === this.postModelData.type_id);
                            if(foundTypeItem && foundTypeItem !== null){
                                this.postModelData.subject = `${machineInfoId}${targetItemIdentity}[${this.element.userInfo.username}] - [${foundItem.displayName}] - ${foundTypeItem.displayName}`;
                                this.postModelData.mess = `${machineInfoId}${targetItemIdentity}[${this.element.userInfo.username}], [${foundItem.displayName}] - ${foundTypeItem.displayName}`;
                                return;
                            }
                        }
                        this.postModelData.subject = `${machineInfoId}${targetItemIdentity}[${this.element.userInfo.username}] - ${foundItem.displayName}`;
                        this.postModelData.mess = `${machineInfoId}${targetItemIdentity}[${this.element.userInfo.username}], ${foundItem.displayName}`;
                    }
                    else {
                        this.postModelData.subject = `${machineInfoId}${targetItemIdentity}[${this.element.userInfo.username}]`;
                        this.postModelData.mess = `${machineInfoId}${targetItemIdentity}[${this.element.userInfo.username}] `;
                    }
                }
            },
            submitTicket() {
                this.$confirm('Support ticket will be created when you press Ok. Continue?', 'Warning', {
                    confirmButtonText: 'OK',
                    cancelButtonText: 'Cancel',
                    type: 'warning'
                }).then(() => {
                    if (this.postModelData) {
                        this.isLoading = true;
                        let foundItem = this.ticketCategory.find(x => x.id === this.postModelData.category_id);
                        let foundTypeItem = this.ticketCase.find(x => x.id === this.postModelData.type_id);
                        let postModelDataRequest = { 
                            ...this.postModelData, 
                            categoryId: foundItem.id,
                            typeId: foundTypeItem.id,
                        };
                        supportTicketApi.submitTicket(postModelDataRequest).then(response => {
                            if (response.data && response.data.result === 0 && response.data.data !== null) {
                                this.$message({
                                    type: 'success',
                                    message: 'Successfully created support ticket!'
                                });
                                this.$emit("closeSupportTicket");
                            }
                            else {
                                if (response.data && response.data.message !== null && response.data.message !== '') {
                                    console.error(response.data.message || this.$lang.common.error);
                                    this.$message.error(response.data.message || this.$lang.common.error);
                                }
                                else{
                                    this.$message.error(this.$lang.common.error);
                                }
                            }
                            this.isLoading = false;
                        }).catch(error => {
                            console.error(error);
                            this.isLoading = false;
                        });
                    }
                }).catch(() => {
                    
                });
            }
        }
    }
</script>